
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditKycEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      description: "",
      cummulativeWithdrawerLimit: "",
      dailyWithdrawerLimit: "",
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(`/admin/kyc/${route.params.kycId}`, {
          description: formData.value.description,
          cummulativeWithdrawerLimit: formData.value.cummulativeWithdrawerLimit,
          dailyWithdrawerLimit: formData.value.dailyWithdrawerLimit,
        });
        ShowSuccess("Entry Updated Successfully");
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/admin/kyc`);

        // const responseGetKycInfo = await apiGet(
        //   `/admin/kyc/${route.params.kycId}`
        // );

        // console.log(responseGetKycInfo);

        // console.log(response);

        const kyc = response.data.data.kycsettings;

        let kycObject = kyc.find(
          (entry: { id: string }) => entry.id == route.params.kycId
        );

        formData.value = {
          ...kycObject,
          cummulativeWithdrawerLimit: kycObject.cummulative_withdrawer_limit,
          dailyWithdrawerLimit: kycObject.daily_withdrawer_limit,
        };

        console.log(formData.value);

        // formData.value.code = entry.code;
        // formData.value.name = entry.name;
        // formData.value.usdRate = entry.usd_rate;

        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Response fetched",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
